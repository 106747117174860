[class^='speech-']:before {
	content: "";
  width: 0px;
  height: 0px;
  position: absolute;
	top: 5px;
	z-index: 2;
	border-bottom: 10px solid transparent;
}

.speech-warning-left:before {
	left: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid var(--ion-color-warning);
  border-top: 10px solid var(--ion-color-warning);
}

.speech-warning-right:before {
	right: 10px;
  border-left: 10px solid var(--ion-color-warning);
  border-right: 10px solid transparent;
  border-top: 10px solid var(--ion-color-warning);
}

.speech-primary:before {
	right: 10px;
  border-left: 10px solid var(--ion-color-primary);
  border-right: 10px solid transparent;
  border-top: 10px solid var(--ion-color-primary);
}

.speech-light:before {
	left: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid var(--ion-color-light);
  border-top: 10px solid var(--ion-color-light);
}

.speech-success:before {
	left: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid var(--ion-color-success);
  border-top: 10px solid var(--ion-color-success);
}

.speech-dark:before {
	left: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid var(--ion-color-dark);
  border-top: 10px solid var(--ion-color-dark);
}